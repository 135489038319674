import * as React from "react"

//styles
import "../styles/css/styles.css"

import defaults from "../components/dafaults"

// components
import Header from "../components/Header"
import Footer from "../components/Footer"
import Search from "../components/Search/index"
import Banner from "../components/Banner"
import Slider from "../components/Slider"
import List from "../components/List"
import Icon from "../components/Icon"

import bannerDesktop from "../../assets/images/desktop.main-banner.jpg"
import bannerMobile from "../../assets/images/mobile.main-banner.jpg"

import applestore from "../../assets/images/icons/apple-store.svg"
import googleplay from "../../assets/images/icons/google-play.svg"
import Pagination from "../components/Pagination"


export default function Lancamentos() {
  const [ lancamentos, setLancamentos ] = React.useState(null)
  const [ dicas, setDicas ] = React.useState(null)
  const [ currentPage ,  setCurrentPage ] = React.useState(1);

  React.useLayoutEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    fetch(`${defaults.api.rest}lancamento?per_page=12&page=${currentPage}&_embed`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(lancamentos == null)
          setLancamentos({...lancamentos, [currentPage]: result })
      })
      .catch(error => console.log('error', error));

  })

  React.useEffect(() => {

    console.log("🚀 ~ file: lancamento.js:76 ~ Home ~ currentPage", currentPage)

    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    fetch(`${defaults.api.rest}lancamento?per_page=12&page=${currentPage}&_embed`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("🚀 ~ file: lancamento.js:67 ~ React.useEffect ~ lancamentos[currentPage]", lancamentos[currentPage])
        if(lancamentos[currentPage] == undefined)
          setLancamentos({...lancamentos, [currentPage]: result })
      })
      .catch(error => console.log('error', error));

  }, [currentPage])

  console.log("🚀 ~ file: lancamento.js:41 ~ React.useLayoutEffect ~ lancamentos", lancamentos)
  
  return(
    <>
      <Header />
      {/* <Search /> */}
        <section className="dicasPage">
          <div className="container dicas-title">
              <h2>Lançamento</h2>
              {/* <div className="navigation">
                <a href="/dicas-tecnicas">Ver todas as dicas</a>
              </div> */}
          </div>
          <div className="dicas-list">
            {
              lancamentos == null ?
                <span>Carregando</span>
              :
                <>
                <List datas={lancamentos[currentPage]} buttonLabel="Saiba Mais" path="/lancamento" />
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </>
            }
          </div>
        </section>
      <Footer />
    </>
  )
}

export const Head = () => <title>Wega Motors</title>